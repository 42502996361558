import { amgApi } from "@/service/axios";

class BillsService {

    async searchItemsLogistic(params) {
        try {
            const data = await amgApi.post("/logistics/bills/search-items-logistic", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getPredefinedSpecificationsBySubcategory(params) {
        try {
            const data = await amgApi.post("/logistics/bills/get-predefined-specifications-by-subcategory", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getVendors(params) {
        try {
            const data = amgApi.post("/logistics/bills/get-vendors", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getCountries() {
        try {
            const data = amgApi.get("/logistics/bills/get-countries");
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getRates(params) {
        try {
            const data = amgApi.post("/logistics/bills/get-rates", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getBrands() {
        try {
            const data = amgApi.get("/logistics/bills/get-brands");
            return data;
        } catch (error) {
            throw error;
        }
    }
    async saveBrand(parameters) {
        try {
            const data = await amgApi.post('logistics/bills/save-brand', parameters)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async insertPurchaseOrder(params) {
        try {
            const data = await amgApi.post("/logistics/bills/insert-purchase-order", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async editPurchaseOrder(params) {
        try {
            const data = await amgApi.post("/logistics/bills/edit-purchase-order", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getPruchaseOrders(params) {
        try {
            const data = amgApi.post("/logistics/bills/get-purchase-orders", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getPurchaseTypes() {
        try {
            const data = amgApi.get("/logistics/bills/get-purchase-types");
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getDataPurchaseOrderById(params) {
        try {
            const data = await amgApi.post("/logistics/bills/get-data-purchase-order-by-id", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async approvePurchaseOrder(params) {
        try {
            const data = await amgApi.post("/logistics/bills/approve-purchase-order", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getTotalAmountByYear(params) {
        try {
            const data = await amgApi.post("/logistics/bills/get-total-amount-by-year", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getExchangeRate() {
        try {
            const data = await amgApi.post("/logistics/bills/get-exchange-rate");
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new BillsService();