<template>
  <div>
    <b-modal v-model="showModal" size="lg" :title="titleModal" @hidden="closeModal">
      
      <ValidationObserver ref="form">
        <div>
          <b-row>
            <!-- Image -->
            <b-col md="6" xl="6" class="m-auto">
              <div
                class="form-group ml-3 mr-3"
                style="
                  border: 2px solid black;
                  border-radius: 10px;
                  padding: 10px;
                "
              >
                <div class="image-upload">
                  <input
                    type="file"
                    id="file_input_1"
                    @change="getImage"
                    hidden
                  />
                  <!-- INPUT_FILE FIN -->
                  <div class="form-group m-0" style="cursor: pointer">
                    <figure @click="inputImage()" class="m-0">
                      <img width="100%" height="100%" :src="image" />
                    </figure>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col>
              <b-row>
                <!-- Categories -->
                <b-col sm="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-group label="Category:">
                      <b-form-select
                        :options="categories"
                        v-model="form.category_id"
                        value-field="id"
                        text-field="name"
                        class="select-icon-none"
                        :class="{ 'border border-danger': errors[0] }"
                        :disabled="typeModal == 'EDIT'"
                      >
                        <template #first>
                            <b-form-select-option :value="null" disabled>Select a category</b-form-select-option>
                        </template>
                      </b-form-select>
                      <span
                      v-if="errors[0]"
                      class="text-danger"
                      >
                        Category {{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- Subcategories -->
                <b-col sm="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-group label="Subcategory:">
                      <b-form-select
                        :options="subcategories"
                        v-model="form.subcategory_id"
                        value-field="id"
                        text-field="name"
                        class="select-icon-none"
                        :class="{ 'border border-danger': errors[0] }"
                        :disabled="subcategoryDisabled || typeModal == 'EDIT'"
                      >
                        <template #first>
                            <b-form-select-option :value="null" disabled>Select a subcategory</b-form-select-option>
                        </template>
                      </b-form-select>
                      <span
                      v-if="errors[0]"
                      class="text-danger"
                      >
                        Subcategory {{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- PRODUCT NAME -->
                <b-col sm="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-group label="Name:">
                      <b-form-input
                        v-model="form.name"
                        class="input-background-white"
                        :class="{ 'border border-danger': errors[0] }"
                        :disabled="typeModal == 'EDIT'"
                      ></b-form-input>
                      <span
                          v-if="errors[0]"
                          class="text-danger"
                          >
                          Name {{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- INITIAL STOCK -->
                <b-col sm="12" v-if="showInputStock">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-group
                      label="Initial stock"
                    >
                      <b-form-input
                        v-model="form.initial_stock"
                        class="input-background-white"
                        type="number"
                        :class="{ 'border border-danger': errors[0] }"
                      ></b-form-input>
                      <span
                        v-if="errors[0]"
                        class="text-danger"
                        >
                          Initial stock {{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <!-- MINIMUN STOCK -->
                <b-col sm="12">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-group
                      label="Minimun stock"
                    >
                      <b-form-input
                        v-model="form.minimum_stock"
                        class="input-background-white"
                        type="number"
                        :class="{ 'border border-danger': errors[0] }"
                      ></b-form-input>
                      <span
                        v-if="errors[0]"
                        class="text-danger"
                        >
                          Minimun stock {{ errors[0] }}</span>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>
            
          </b-row>
        </div>
      </ValidationObserver>
      
      <template #modal-footer="{ cancel }">
      <!-- Footer: Left Content -->
        <b-button 
          variant="danger"
          class="px-1"
          @click="cancel()"
          >CANCEL</b-button
        >
        <b-button 
          variant="primary"
          class="px-1"
          @click="saveData()"
          >{{titleButton}}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import InventoryService from '@/views/logistic/views/inventory2/services/logistic-inventory.service';
import {mapActions} from 'vuex'
export default {
  props: {
    typeModal: String,
    item: Object,
  },
  components: {
    
  },
  data() {
    return {
        categories: [],
        subcategories: [],
        subcategoryDisabled: true,
        form: {
            category_id: null,
            subcategory_id: null,
        },
        showModal: false,
        showInputStock: true,
        viewImage: null,
        urlImage: "/assets/images/product.png",
    };
  },
  async created() {
    this.valuateData();
  },
  computed: { 
    titleModal(){
      let aux = this.typeModal == "ADD" ? "CREATE" : "EDIT";
      return aux + " ITEM";
    },
    titleButton(){
      return this.typeModal == "ADD" ? "SAVE" : "UPDATE";
    },
    image() {
      if (!this.viewImage) {
        return this.urlImage;
      } else {
        return this.viewImage;
      }
    },
  },
  watch:{
    "form.category_id": async function(value){
        const { data } = await InventoryService.getItemsSubcategory({ category_id: value });
        this.subcategories = data;
        this.subcategoryDisabled = false;

        if(value == 1 || value == 3){
            this.showInputStock = false;
            this.form.initial_stock = 0;
        }else{
            this.showInputStock = true;
        }
    },
  },
  methods: {    
    ...mapActions({
      CHANGE_REFRESH_TABLE: "InventoryLogistic/CHANGE_REFRESH_TABLE",
    }),
    closeModal(){
      this.$emit("closeModal")
    },
    async saveData(){
      const validation = await this.$refs.form.validate();
      if (validation) {
        const formData = new FormData();
        formData.append("id", this.form.item_type_id);
        formData.append("category", this.form.category_id);
        formData.append("subcategory", this.form.subcategory_id);
        formData.append("name", this.form.name);
        formData.append("stockini", this.form.initial_stock);
        formData.append("stockmin", this.form.minimum_stock);
        formData.append("isDetailed", this.valuateCategory());
        if(this.form.images != null || this.form.images != undefined) {
          formData.append("image", this.form.images);
        }
        formData.append("filePath", this.form.filePath);
        const confirm = await this.showConfirmSwal();
        if(confirm.value){
          this.addPreloader();
          let typeService = this.typeModal == "ADD" ? "saveItem" : "updateItem";
          try {
            let {data} = await InventoryService[typeService](formData)
            if (data.success) {
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Successful operation"
                );
                if(this.typeModal == "ADD"){
                  this.CHANGE_REFRESH_TABLE(true);
                }else{
                  this.refreshTable();
                }
                this.closeModal();
              } else {
                this.showToast(
                  "danger",
                  "top-right",
                  "Failed!",
                  "XIcon",
                  "Failed operation"
                );
              }
          } catch (error) {
            this.showErrorSwal(error);
          }
          this.removePreloader();
        }
      }      
    },
    getImage(e) {
      console.log(e);
      let file = e.target.files[0];
      this.form.images = file;
      this.uploadImage(file);
    },

    uploadImage: function (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.viewImage = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    inputImage() {
      document.getElementById("file_input_1").click();
    },
    async getItemsCategory() {
      const { data } = await InventoryService.getItemsCategory();
      this.categories = data;
    },
    async getItemsSubCategory(categoryId) {
      const { data } = await InventoryService.getItemsSubcategory({ category_id: categoryId });
      this.subcategories = data;
    },
    valuateCategory(){
        let search = this.categories.find(category => category.id == this.form.category_id);
        if(search.name == "Equipments" || search.name == "Furniture"){
            return 1;
        }else{
            return 0;
        }
    },
    async valuateData(){
      if(this.typeModal == 'EDIT'){
        await this.getItemsSubCategory(this.item.category_id);
        this.subcategoryDisabled = false;
        this.form = {...this.item}
        this.form.name = this.item.item_name;
        this.viewImage = this.item.path;
      }
      await this.getItemsCategory();
      this.showModal = true;
    },
    closeModal(){
      this.$emit("closeModal")
    },
    refreshTable(){
      this.$emit("refreshTable")
    }
  },
};
</script>