var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-register-existent-items",staticClass:"modal-register",attrs:{"no-close-on-backdrop":"","size":"xl","title":"REGISTER EXISTENT ITEMS"},on:{"hidden":_vm.cancel},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" CANCEL ")])],1),_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveData()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" SAVE ")],1)],1)]},proxy:true}])},[_c('div',[_c('div',{staticClass:"ml-1"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex ml-1"},[_c('validation-provider',{staticClass:"d-flex",attrs:{"rules":"required","name":"existItems"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0],attrs:{"label":"SELECT ITEM"}},[_c('v-select',{staticStyle:{"min-width":"150px !important"},attrs:{"options":_vm.items,"reduce":function (item) { return item.id; },"label":"name"},on:{"input":_vm.onChangeItem},model:{value:(_vm.existItems),callback:function ($$v) {_vm.existItems=$$v},expression:"existItems"}})],1),(_vm.existItems)?_c('div',{staticClass:"form-group"},[_c('b-button',{ref:"btnShow",staticClass:"ml-1",staticStyle:{"margin-top":"2.2rem"},attrs:{"variant":"success","size":"sm","disabled":_vm.isLoading},on:{"click":_vm.agregarItem}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"PlusIcon","size":"15"}})],1)],1):_vm._e()]}}])})],1),_c('div',{staticClass:"mt-1 mr-2",class:_vm.isDarkSkin? 'counter-wrapper' : 'counter-wrapper-light'},[_c('span',{staticClass:"counter"},[_vm._v("ITEMS: "+_vm._s(_vm.brandCounter))])])])]),_c('div',{staticClass:"ml-1 d-flex custom-scroll"},[_c('validation-observer',{ref:"observer"},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.fields),function(field,index){return _c('div',{key:'field_' + index,staticClass:"d-flex"},[_c('div',[_c('div',{attrs:{"label":field.label}},[(field.type === 'v-select')?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0]},[_c('v-select',{attrs:{"options":field.options,"label":"name","append-to-body":"","calculate-position":_vm.positionDropdown,"reduce":function (option) { return option.id; }},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:(field.type === 'flat-pickr')?[_c('validation-provider',{attrs:{"rules":"required","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:errors[0] ? 'error-date' : ''},[_c('flat-pickr',{staticClass:"form-control",class:{ 'border border-danger': errors[0] },attrs:{"config":field.config},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:(field.type === 'money')?[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|validate-amount","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",class:{ 'border-danger': errors[0] },attrs:{"prefix":_vm.currencyPrefix},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'money',_vm.money,false))]}}],null,true)})],1)]:(field.type === 'money-soles')?[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|validate-amount","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",class:{ 'border-danger': errors[0] },model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'money',_vm.moneySoles,false))]}}],null,true)})],1)]:(field.type == 'number')?[_c('validation-provider',{attrs:{"rules":"required|custom_numeric","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:errors[0] ? 'error-date' : ''},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"input-1","type":"number"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{staticClass:"form-control",class:errors[0],attrs:{"type":"text","maxlength":field.maxlength},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]],2)])])}),0),_vm._l((_vm.additionalItems),function(additionalItem,itemIndex){return _c('div',{key:'additional_' + itemIndex,staticClass:"d-flex"},[_vm._l((additionalItem.fields),function(field,fieldIndex){return _c('div',{key:'field_' + itemIndex + '_' + fieldIndex,staticClass:"form-group col-md-2"},[_c('b-form-group',[_c('span',[_vm._v(_vm._s(field.label))]),(field.label == 'BRAND')?_c('feather-icon',{staticClass:"pointer text-primary",staticStyle:{"float":"right"},attrs:{"icon":"PlusCircleIcon","size":"16"},on:{"click":function($event){return _vm.openModalAddBrand()}}}):_vm._e(),(field.type === 'v-select' && field.label == 'CURRENCY')?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0]},[_c('v-select',{attrs:{"options":field.options,"label":"name","reduce":function (option) { return option.id; },"append-to-body":"","calculate-position":_vm.positionDropdown},on:{"input":function (selectedValue) { return _vm.handleCurrencyChange(selectedValue, itemIndex); }},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:(field.type === 'v-select' && field.label == 'AVAILABILITY STATUS')?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0]},[_c('v-select',{attrs:{"options":field.options,"label":"name","reduce":function (option) { return option.id; },"append-to-body":"","calculate-position":_vm.positionDropdown},on:{"input":function (selectedValue) { return _vm.handleConditionStatusChange(selectedValue, itemIndex); }},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:(field.type === 'v-select' && field.label == 'MODULE')?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0]},[_c('v-select',{attrs:{"options":field.options,"label":"name","reduce":function (option) { return option.id; },"append-to-body":"","calculate-position":_vm.positionDropdown},on:{"input":function (selectedValue) { return _vm.handleModuleChange(selectedValue, itemIndex); }},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:(field.type === 'v-select' )?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors[0]},[_c('v-select',{attrs:{"options":field.options,"label":"name","reduce":function (option) { return option.id; },"append-to-body":"","calculate-position":_vm.positionDropdown},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:(field.type === 'flat-pickr')?[_c('validation-provider',{attrs:{"rules":"required","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:errors[0] ? 'error-date' : ''},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":field.config},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:(field.type === 'money')?[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|validate-amount","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",class:{ 'border-danger': errors[0] },model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'money',_vm.money,false))]}}],null,true)})],1)]:(field.type === 'money-soles')?[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required|validate-amount","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",class:{ 'border-danger': errors[0]},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},'money',_vm.moneySoles,false))]}}],null,true)})],1)]:(field.type == 'number')?[_c('validation-provider',{attrs:{"rules":"required","name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{class:errors[0] ? 'error-date' : ''},[_c('b-form-input',{staticClass:"form-control",attrs:{"id":"input-1","type":"number"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)]}}],null,true)})]:[_c('validation-provider',{attrs:{"rules":field.rules,"name":field.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",class:errors[0],attrs:{"type":"text","maxlength":field.maxlength},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})]}}],null,true)})]],2)],1)}),_c('div',{staticStyle:{"padding":"30px"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-danger mr-2 cursor-pointer",attrs:{"icon":"TrashIcon","size":"24","title":"DELETE ITEM"},on:{"click":function($event){return _vm.deleteItem(itemIndex)}}})],1),(_vm.brandCounter > 1)?_c('div',{staticStyle:{"margin-top":"30px"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary mr-2 cursor-pointer",attrs:{"icon":"CopyIcon","size":"24","title":"COPY ITEM"},on:{"click":function($event){return _vm.copyValues(itemIndex)}}})],1):_vm._e()],2)})],2)],1)])]),_c('b-modal',{attrs:{"modal":"","title":"CREATE BRAND","ok-title":"SAVE"},on:{"ok":function($event){$event.preventDefault();return _vm.registerBrand.apply(null, arguments)}},model:{value:(_vm.ModalAddBrand),callback:function ($$v) {_vm.ModalAddBrand=$$v},expression:"ModalAddBrand"}},[_c('div',[_c('ValidationObserver',{ref:"formBrand"},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"BRAND:","label-for":"txtBrand"}},[_c('b-form-input',{attrs:{"id":"txtBrand","placeholder":"...","state":errors[0] ? false : null},model:{value:(_vm.newBrand),callback:function ($$v) {_vm.newBrand=$$v},expression:"newBrand"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Brand "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }