<template>
  <div>
    <b-modal
      ref="modal-register-existent-items"
      no-close-on-backdrop
      size="xl"
      title="REGISTER EXISTENT ITEMS"
      class="modal-register"
      @hidden="cancel"
    >
      <div>
        <div class="ml-1">
          <div class="d-flex">
            <div class="d-flex ml-1">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="existItems"
                class="d-flex"
              >
                <b-form-group
                  :class="errors[0]"
                  label="SELECT ITEM"
                >
                  <v-select
                    v-model="existItems"
                    style="min-width: 150px !important;"
                    :options="items"
                    :reduce="(item) => item.id"
                    label="name"
                    @input="onChangeItem"
                  />
                </b-form-group>
                <div
                  v-if="existItems"
                  class="form-group"
                >
                  <b-button
                    ref="btnShow"
                    variant="success"
                    size="sm"
                    style="margin-top: 2.2rem;"
                    class="ml-1"                    
                    :disabled="isLoading"
                    @click="agregarItem"
                  ><feather-icon
                    icon="PlusIcon"
                    size="15"
                    class="text-white"
                  />
                  </b-button>
                </div>
              </validation-provider>
            </div>
            <div
              class="mt-1 mr-2"
              :class="isDarkSkin? 'counter-wrapper' : 'counter-wrapper-light'"
            >
              <span class="counter">ITEMS: {{ brandCounter }}</span>
            </div>
          </div>
        </div>
        <div class="ml-1 d-flex custom-scroll">
          <validation-observer ref="observer">
            <div class="d-flex">
              <div
                v-for="(field, index) in fields"
                :key="'field_' + index"
                class="d-flex"
              >
                <div>
                  <div :label="field.label">
                    <template v-if="field.type === 'v-select'">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <b-form-group :class="errors[0]">
                          <v-select
                            v-model="field.value"
                            :options="field.options"
                            label="name"
                            append-to-body
                            :calculate-position="positionDropdown"
                            :reduce="(option) => option.id"
                          />
                        </b-form-group>
                      </validation-provider>
                    </template>
                    <template v-else-if="field.type === 'flat-pickr'">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        :name="field.label"
                      >
                        <div :class="errors[0] ? 'error-date' : ''">
                          <flat-pickr
                            v-model="field.value"
                            class="form-control"
                            :config="field.config"
                            :class="{ 'border border-danger': errors[0] }"
                          />
                        </div>
                      </validation-provider>
                    </template>
                    <template v-else-if="field.type === 'money'">
                      <b-form-group>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|validate-amount"
                          :name="field.label"
                        >
                          <money
                            v-model="field.value"
                            class="form-control"
                            v-bind="money"
                            :prefix="currencyPrefix"
                            :class="{ 'border-danger': errors[0] }"
                          />
                        </validation-provider>
                      </b-form-group>
                    </template>
                    <template v-else-if="field.type === 'money-soles'">
                      <b-form-group>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|validate-amount"
                          :name="field.label"
                        >
                          <money
                            v-model="field.value"
                            class="form-control"
                            :class="{ 'border-danger': errors[0] }"
                            v-bind="moneySoles"
                          />
                        </validation-provider>
                      </b-form-group>
                    </template>
                    <template v-else-if="field.type == 'number'">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|custom_numeric"
                        :name="field.label"
                      >
                        <div :class="errors[0] ? 'error-date' : ''">
                          <b-form-input
                            id="input-1"
                            v-model="field.value"
                            class="form-control"
                            type="number"
                          />
                        </div>
                      </validation-provider>
                    </template>
                    <template v-else>
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="field.rules"
                        :name="field.label"
                      >
                        <b-form-group>
                          <b-form-input
                            v-model="field.value"
                            type="text"
                            class="form-control"
                            :maxlength="field.maxlength"
                            :class="errors[0]"
                          />
                        </b-form-group>
                      </validation-provider>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="(additionalItem, itemIndex) in additionalItems"
              :key="'additional_' + itemIndex"
              class="d-flex"
            >
              <div
                v-for="(field, fieldIndex) in additionalItem.fields"
                :key="'field_' + itemIndex + '_' + fieldIndex"
                class="form-group col-md-2"
              >
                <b-form-group>
                  <span>{{ field.label }}</span>
                  <feather-icon
                      v-if="field.label == 'BRAND'"
                      class="pointer text-primary"
                      style="float: right"
                      icon="PlusCircleIcon"
                      size="16"
                      @click="openModalAddBrand()"
                    />
                  <template v-if="field.type === 'v-select' && field.label == 'CURRENCY'">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-group :class="errors[0]">
                        <v-select
                          v-model="field.value"
                          :options="field.options"
                          label="name"
                          :reduce="(option) => option.id"
                          append-to-body
                          :calculate-position="positionDropdown"
                          @input="(selectedValue) => handleCurrencyChange(selectedValue, itemIndex)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </template>

                  <template v-else-if="field.type === 'v-select' && field.label == 'AVAILABILITY STATUS'">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-group :class="errors[0]">
                        <v-select
                          v-model="field.value"
                          :options="field.options"
                          label="name"
                          :reduce="(option) => option.id"
                          append-to-body
                          :calculate-position="positionDropdown"
                          @input="(selectedValue) => handleConditionStatusChange(selectedValue, itemIndex)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </template>

                  <template v-else-if="field.type === 'v-select' && field.label == 'MODULE'">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-group :class="errors[0]">
                        <v-select
                          v-model="field.value"
                          :options="field.options"
                          label="name"
                          :reduce="(option) => option.id"
                          append-to-body
                          :calculate-position="positionDropdown"
                          @input="(selectedValue) => handleModuleChange(selectedValue, itemIndex)"
                        />
                      </b-form-group>
                    </validation-provider>
                  </template>

                  <template v-else-if="field.type === 'v-select' ">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-form-group :class="errors[0]">
                        <v-select
                          v-model="field.value"
                          :options="field.options"
                          label="name"
                          :reduce="(option) => option.id"
                          append-to-body
                          :calculate-position="positionDropdown"
                        />
                      </b-form-group>
                    </validation-provider>
                  </template>

                  <template v-else-if="field.type === 'flat-pickr'">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="field.label"
                    >
                      <div :class="errors[0] ? 'error-date' : ''">
                        <flat-pickr
                          v-model="field.value"
                          class="form-control"
                          :config="field.config"
                        />
                      </div>
                    </validation-provider>
                  </template>
                  <template v-else-if="field.type === 'money'">
                    <b-form-group>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|validate-amount"
                        :name="field.label"
                      >
                        <money
                          v-model="field.value"
                          class="form-control"
                          v-bind="money"
                          :class="{ 'border-danger': errors[0] }"
                        />
                      </validation-provider>
                    </b-form-group>
                  </template>

                  <template v-else-if="field.type === 'money-soles'">
                    <b-form-group>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|validate-amount"
                        :name="field.label"
                      >
                        <money
                          v-model="field.value"
                          class="form-control"
                          v-bind="moneySoles"
                          :class="{ 'border-danger': errors[0]}"
                        />
                      </validation-provider>
                    </b-form-group>
                  </template>
                  <template v-else-if="field.type == 'number'">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      :name="field.label"
                    >
                      <div :class="errors[0] ? 'error-date' : ''">
                        <b-form-input
                          id="input-1"
                          v-model="field.value"
                          class="form-control"
                          type="number"
                        />
                      </div>
                    </validation-provider>
                  </template>
                  <template v-else>
                    <validation-provider
                      v-slot="{ errors }"
                      :rules="field.rules"
                      :name="field.label"
                    >
                      <b-form-input
                        v-model="field.value"
                        type="text"
                        class="form-control"
                        :class="errors[0]"
                        :maxlength="field.maxlength"
                      />
                    </validation-provider></template>
                </b-form-group>
              </div>
              <div style="padding: 30px;">
                <feather-icon
                  v-b-tooltip.hover
                  icon="TrashIcon"
                  class="text-danger mr-2 cursor-pointer"
                  size="24"
                  title="DELETE ITEM"
                  @click="deleteItem(itemIndex)"
                />
              </div>
              <div v-if="brandCounter > 1" style="margin-top: 30px;">
                <feather-icon
                  v-b-tooltip.hover
                  icon="CopyIcon"
                  class="text-primary mr-2 cursor-pointer"
                  size="24"
                  title="COPY ITEM"
                  @click="copyValues(itemIndex)"
                />
              </div>
            </div>
          </validation-observer>
        </div>
      </div>

      <template #modal-footer>
        <div>
          <b-button
            class=""
            @click="cancel()"
          >
            CANCEL
          </b-button>
        </div>
        <div>
          <b-button
            variant="primary"
            @click="saveData()"
          >
            <feather-icon
              icon="SaveIcon"
            /> SAVE
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      modal
      v-model="ModalAddBrand"
      title="CREATE BRAND"
      @ok.prevent="registerBrand"
      ok-title="SAVE"
    >
      <div>
        <ValidationObserver ref="formBrand">
          <b-row>
            <b-col sm="12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-group label="BRAND:" label-for="txtBrand">
                  <b-form-input
                    v-model="newBrand"
                    id="txtBrand"
                    placeholder="..."
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    Brand {{ errors[0] }}
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import { Money } from 'v-money';
import flatPickr from 'vue-flatpickr-component';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, numeric, max } from 'vee-validate/dist/rules';
import InventoryService from '@/views/logistic/views/inventory2/services/logistic-inventory.service';
import BillsService from '@/views/logistic/views/bills/service/bills.service';

extend('required', {
  ...required,
  message: 'is-invalid',
});
extend('custom_numeric', {
  validate: value => /^\d{1,2}$/.test(value),
  message: 'This field must be a numeric value with at most 2 digits',
});

extend('max', {
  ...max,
  message: 'The {_field_} field may not be greater than {length} characters',
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    flatPickr,
    Money,
  },
  directives: { money: Money },
  data() {
    return {
      isLoading: false,
      currencyPrefix: '$',
      money: {
        decimal: ',',
        prefix: '$ ',
        thousands: '.',
        precision: 2,
        maxlength: 16,
      },
      moneySoles: {
        decimal: ',',
        prefix: 'S/. ',
        thousands: '.',
        precision: 2,
        maxlength: 16,
      },
      items: [],
      listUsers: [],
      itemsBrand: [],
      existItems: null,
      fields: [],
      additionalItems: [],
      itemsModules: [],
      conditionStatus: [
        { id: 1, name: 'AVAILABLE' },
        { id: 2, name: 'RESERVED' },
        { id: 3, name: 'ASSIGNED' },
        { id: 4, name: 'REMOVED' },
      ],
      availabilityStatus: [
        { id: 1, name: 'NEW' },
        { id: 2, name: 'USED' },
        { id: 3, name: 'REPAIRED' },
        { id: 4, name: 'DAMAGED' },
      ],
      currency: [
        { id: 1, name: '$' },
        { id: 2, name: 'S/' },
      ],
      country: [
        { id: 75, name: 'UNITED STATES' },
        { id: 173, name: 'PERU' },
      ],
      brandCounter: 0,
      ItemsPredefinedSpecifications: [],
      exchangeRate: 0,
      ModalAddBrand: false,
      newBrand: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.toggleModal('modal-register-existent-items');
  },
  async created() {
    this.getItemsLogistic();
    this.getBrands();
    this.getAllModules();
    await this.getExchangeRate();
  },
  methods: {
    agregarItem() {
      // si van a modificar los nombres de los labels de la defaultFields tienen que modificar el sp de registro con los nuevos nombres
      const defaultFields = [
        {
          label: 'BRAND',
          type: 'v-select',
          value: '',
          rules: 'required',
          options: this.itemsBrand,
        },
        {
          label: 'AVAILABILITY STATUS',
          type: 'v-select',
          value: '',
          rules: 'required',
          options: this.conditionStatus,
        },
        {
          label: 'CONDITION STATUS',
          type: 'v-select',
          value: '',
          rules: 'required',
          options: this.availabilityStatus,
        },
        {
          label: 'COUNTRY',
          type: 'v-select',
          value: '',
          rules: 'required',
          options: this.country,
        },
        {
          label: 'CURRENCY',
          type: 'v-select',
          value: '',
          rules: 'required',
          options: this.currency,
        },
        {
          label: 'DATE OF PURCHASE',
          type: 'flat-pickr',
          value: '',
          rules: 'required',
          config: { dateFormat: 'Y-m-d' },
        },
        { label: 'LIFE TIME MONTHS', value: '', type: 'number' },
        {
          label: 'SALVAGE VALUE', value: '', rules: 'required', type: 'money',
        },
        {
          label: 'PRICE', value: '', rules: 'required', type: 'money',
        },
        {
          label: 'EXCHANGE RATE', value: this.exchangeRate, rules: 'required', type: 'money-soles',
        },
      ];

      const dynamicFields = this.ItemsPredefinedSpecifications.map(item => ({
        label: item.specification,
        value: '',
      }));

      const additionalItem = {
        fields: [...defaultFields, ...dynamicFields],
      };

      this.additionalItems.push(additionalItem);
      this.brandCounter++;
    },
    deleteItem(itemIndex) {
      this.additionalItems.splice(itemIndex, 1);
      this.brandCounter--;
    },
    copyValues(sourceIndex) {
      const sourceItem = this.additionalItems[sourceIndex];

      const hasModuleField = sourceItem.fields.some(field => field.label === 'MODULE');
      const hasUsersField = sourceItem.fields.some(field => field.label === 'USERS');

      for (let i = 0; i < this.additionalItems.length; i++) {
        if (i !== sourceIndex) {
          const existingItem = this.additionalItems[i];

          for (const field of sourceItem.fields) {
            const existingField = existingItem.fields.find(f => f.label === field.label);
            if (existingField) {
              existingField.value = field.value;
            }
          }

          if (hasModuleField && !existingItem.fields.some(field => field.label === 'MODULE')) {
            const moduleField = sourceItem.fields.find(field => field.label === 'MODULE');
            const nullModuleField = {
              ...moduleField,
              value: null,
            };
            const availabilityStatusIndex = existingItem.fields.findIndex(f => f.label === 'AVAILABILITY STATUS');
            existingItem.fields.splice(availabilityStatusIndex + 1, 0, nullModuleField);
          }

          if (hasUsersField && !existingItem.fields.some(field => field.label === 'USERS')) {
            const usersField = sourceItem.fields.find(field => field.label === 'USERS');
            const nullUsersField = {
              ...usersField,
              value: null,
            };
            const moduleIndex = existingItem.fields.findIndex(f => f.label === 'MODULE');
            existingItem.fields.splice(moduleIndex + 1, 0, nullUsersField);
          }
        }
      }
    },
    hasModuleField(itemFields) {
      return itemFields.some(field => field.label === 'MODULE');
    },

    handleCurrencyChange(option, itemIndex) {
      const hasModule = this.hasModuleField(this.additionalItems[itemIndex].fields);

      if (hasModule) {
        if (option == 2) {
          this.additionalItems[itemIndex].fields[9].type = 'money-soles';
          this.additionalItems[itemIndex].fields[10].type = 'money-soles';
        } else {
          this.additionalItems[itemIndex].fields[9].type = 'money';
          this.additionalItems[itemIndex].fields[10].type = 'money';
        }
      } else if (option == 2) {
        this.additionalItems[itemIndex].fields[7].type = 'money-soles';
        this.additionalItems[itemIndex].fields[8].type = 'money-soles';
      } else {
        this.additionalItems[itemIndex].fields[7].type = 'money';
        this.additionalItems[itemIndex].fields[8].type = 'money';
      }

      const additionalItem = this.additionalItems[itemIndex];
      if (additionalItem) {
        const currencyField = additionalItem.fields.find(
          field => field.label === 'CURRENCY',
        );
        if (currencyField) {
          const selectedCurrency = currencyField.options.find(
            option => option.id === currencyField.value,
          );
          if (selectedCurrency) {
            this.$set(currencyField, 'prefix', selectedCurrency.name);
          }
        }
      }
    },
    async handleConditionStatusChange(selectedValue, itemIndex) {
      if (selectedValue === 3) {
        const programsField = {
          label: 'MODULE',
          type: 'v-select',
          value: '',
          rules: 'required',
          options: this.itemsModules,
        };
        await this.getListUser(selectedValue);

        const programsFieldUsers = {
          label: 'USERS',
          type: 'v-select',
          value: '',
          rules: 'required',
          options: this.listUsers,
        };

        const salvageValueIndex = this.additionalItems[itemIndex].fields.findIndex(
          field => field.label === 'AVAILABILITY STATUS',
        );

        if (salvageValueIndex !== -1) {
          this.additionalItems[itemIndex].fields.splice(salvageValueIndex + 1, 0, programsField);
          this.additionalItems[itemIndex].fields.splice(salvageValueIndex + 2, 0, programsFieldUsers);
        }
      } else {
        const programsFieldIndex = this.additionalItems[itemIndex].fields.findIndex(
          field => field.label === 'MODULE',
        );

        if (programsFieldIndex !== -1) {
          this.additionalItems[itemIndex].fields.splice(programsFieldIndex, 1);

          if (this.additionalItems[itemIndex].fields[programsFieldIndex].label === 'USERS') {
            this.additionalItems[itemIndex].fields.splice(programsFieldIndex, 1);
          }
        }
      }
    },

    async handleModuleChange(selectedValue, itemIndex) {
      const newListUsers = await this.getListUser(selectedValue);

      const programsFieldUsersIndex = this.additionalItems[itemIndex].fields.findIndex(
        field => field.label === 'USERS',
      );
      if (programsFieldUsersIndex !== -1) {
        this.additionalItems[itemIndex].fields[programsFieldUsersIndex].options = newListUsers;
      }
    },
    async getListUser(params) {
      const param = {
        idModule: params,
      };
      try {
        const request = await InventoryService.getListUser(param);
        if (request.status == 200) {
          this.listUsers = request.data || [];
          return this.listUsers;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getBrands() {
      try {
        const request = await BillsService.getBrands();
        if (request.status == 200) {
          this.itemsBrand = request.data || [];
          return this.itemsBrand;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getAllModules() {
      try {
        const request = await InventoryService.getAllModules();
        this.itemsModules = request || [];
        return this.itemsModules;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    async getItemsLogistic() {
      try {
        const request = await InventoryService.getItemsLogistic();
        if (request.status == 200) {
          this.items = request.data || [];
          return this.items;
        }
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    onChangeItem(params) {
      this.additionalItems = [];
      this.brandCounter = 0;
      this.getItemsPredefinedSpecifications(params);
    },
    async getItemsPredefinedSpecifications(params) {
      this.isLoading = true;
      try {
        const parameter = {
          idItem: params,
        };
        const request = await InventoryService.getItemsPredefinedSpecifications(parameter);
        if (request.status == 200) {
          this.ItemsPredefinedSpecifications = request.data;
        }
      } catch (error) {
        console.error(error);
        return [];
      }finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit('closeModal');
    },
    saveCancel() {
      this.$emit('saveCancel');
    },
    async saveData() {
      if (this.existItems) {
        if (this.brandCounter > 0) {
          const result = await this.$refs.observer.validate();
          if (result) {
            try {
              const itemsArray = [];
              const specificationMap = {};

              this.ItemsPredefinedSpecifications.forEach(spec => {
                specificationMap[spec.specification] = spec.id_predefined_specification;
              });
              this.additionalItems.forEach(additionalItem => {
                const item = {};
                additionalItem.fields.forEach(field => {
                  const predefinedSpecId = specificationMap[field.label];
                  if (predefinedSpecId) {
                    if (!item.specification) {
                      item.specification = [];
                    }
                    item.specification.push({
                      id_specification: predefinedSpecId,
                      value: field.value || '\'\'',
                    });
                  } else {
                    item[field.label] = field.value || '\'\'';
                  }
                });

                itemsArray.push(item);
              });

              const params = {
                dataE: itemsArray,
                user_id: this.currentUser.user_id,
                itemLogistic: this.existItems,
              };
              const resultState = await this.showConfirmSwal();
              if (resultState.isConfirmed) {
                this.addPreloader();
                const res = await InventoryService.saveExistentItem(params);
                if (res.status === 200) {
                  this.showSuccessSwal();
                  this.saveCancel();
                }
              }
            } catch (error) {
              console.error(error);
            } finally {
              this.removePreloader();
            }
          } else {
          }
        } else {
          this.showInfoSwal('YOU HAVE TO ADD ITEM');
        }
      } else {
        this.showInfoSwal('YOU HAVE TO SELECT A CATEGORY AND ADD ITEM');
      }
    },
    positionDropdown(dropdownList, component, { width, top, left }) {
      dropdownList.style.zIndex = 9999;
      dropdownList.style.maxHeight = '20rem';
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    async getExchangeRate() {
      try {
        const { data } = await BillsService.getExchangeRate();
        this.exchangeRate = data.venta;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    closeModalAddBrand() {
      this.ModalAddBrand = false;
    },

    openModalAddBrand() {
      this.ModalAddBrand = true;
    },
    async registerBrand() {
      try {
        const resultValidate = await this.$refs.formBrand.validate();
        const validateBrand = this.itemsBrand.includes(
          this.newBrand.toLowerCase()
        );

        if (resultValidate) {
          if (!validateBrand) {
            const response = await this.showConfirmSwal();
            if (response.isConfirmed) {
              this.addPreloader();
              const data = await BillsService.saveBrand({
                name: this.newBrand,
                created_by: this.currentUser.user_id,
              });
              if (data.status === 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Brand has been created successfully"
                );
                this.newBrand = "";
                this.removePreloader();
                this.closeModalAddBrand();
                await this.getBrands();
                const searchLabel = 'BRAND';
                let resultObject = null;

                this.additionalItems.forEach(item => {
                  const fieldsArray = item.fields;
                  const foundObject = fieldsArray.find(field => field.label === searchLabel);
                  if (foundObject) {
                    resultObject = foundObject.options = this.itemsBrand;
                  }
                });
              }
            }
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              "The entered Brand already exists"
            );
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
.modal-register {
  position: relative;
}
.custom-scroll {
  overflow-y: scroll;
}
.counter{
  color: white;
}
.counter-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  background-color: #535454;
  padding: 10px;
  border-radius: 25px;
  z-index: 9999;
}
.counter-wrapper-light{
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
  background-color: #3b8df8;
  padding: 10px;
  border-radius: 25px;
  z-index: 9999;
  color: white;
}
.error-date > * {
border: 1px solid red !important;
}
</style>
