var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"lg","title":_vm.titleModal},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"px-1",attrs:{"variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v("CANCEL")]),_c('b-button',{staticClass:"px-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveData()}}},[_vm._v(_vm._s(_vm.titleButton))])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ValidationObserver',{ref:"form"},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"m-auto",attrs:{"md":"6","xl":"6"}},[_c('div',{staticClass:"form-group ml-3 mr-3",staticStyle:{"border":"2px solid black","border-radius":"10px","padding":"10px"}},[_c('div',{staticClass:"image-upload"},[_c('input',{attrs:{"type":"file","id":"file_input_1","hidden":""},on:{"change":_vm.getImage}}),_c('div',{staticClass:"form-group m-0",staticStyle:{"cursor":"pointer"}},[_c('figure',{staticClass:"m-0",on:{"click":function($event){return _vm.inputImage()}}},[_c('img',{attrs:{"width":"100%","height":"100%","src":_vm.image}})])])])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category:"}},[_c('b-form-select',{staticClass:"select-icon-none",class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.categories,"value-field":"id","text-field":"name","disabled":_vm.typeModal == 'EDIT'},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select a category")])]},proxy:true}],null,true),model:{value:(_vm.form.category_id),callback:function ($$v) {_vm.$set(_vm.form, "category_id", $$v)},expression:"form.category_id"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Category "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Subcategory:"}},[_c('b-form-select',{staticClass:"select-icon-none",class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.subcategories,"value-field":"id","text-field":"name","disabled":_vm.subcategoryDisabled || _vm.typeModal == 'EDIT'},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select a subcategory")])]},proxy:true}],null,true),model:{value:(_vm.form.subcategory_id),callback:function ($$v) {_vm.$set(_vm.form, "subcategory_id", $$v)},expression:"form.subcategory_id"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Subcategory "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name:"}},[_c('b-form-input',{staticClass:"input-background-white",class:{ 'border border-danger': errors[0] },attrs:{"disabled":_vm.typeModal == 'EDIT'},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Name "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),(_vm.showInputStock)?_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Initial stock"}},[_c('b-form-input',{staticClass:"input-background-white",class:{ 'border border-danger': errors[0] },attrs:{"type":"number"},model:{value:(_vm.form.initial_stock),callback:function ($$v) {_vm.$set(_vm.form, "initial_stock", $$v)},expression:"form.initial_stock"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Initial stock "+_vm._s(errors[0]))]):_vm._e()],1)]}}],null,false,3726924006)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Minimun stock"}},[_c('b-form-input',{staticClass:"input-background-white",class:{ 'border border-danger': errors[0] },attrs:{"type":"number"},model:{value:(_vm.form.minimum_stock),callback:function ($$v) {_vm.$set(_vm.form, "minimum_stock", $$v)},expression:"form.minimum_stock"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Minimun stock "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }